import React from 'react';
import { Link } from 'gatsby';
import AccentText from '../components/accent-text';

const NotFoundPage = () => {
  return (
    <>
      <AccentText Tag="h1">Looks like something went wrong here...</AccentText>
      <p>
        Read about what I do{' '}
        <Link to="/" className="u-txt-accent">
          here.
        </Link>
      </p>
      <p>
        Or get in touch over{' '}
        <Link to="/contact/" className="u-txt-accent">
          here.
        </Link>
      </p>
    </>
  );
};

export default NotFoundPage;
