import React, { FunctionComponent } from 'react';

interface AccentHeaderProps {
  Tag: 'h1' | 'h2' | 'h3' | 'p';
}

const AccentText: FunctionComponent<AccentHeaderProps> = ({ Tag, children }) => {
  const text = children ? children.toString() : '';

  return (
    // @ts-ignore
    <Tag className="u-accent-first-letter">
      <span>{text.substr(0, 1)}</span>
      {text.substr(1)}
    </Tag>
  );
};

export default AccentText;
